export const LOGIN_ROUTE_NAME = 'login';
export const LOGOUT_ROUTE_NAME = 'logout';
export const HOME_ROUTE_NAME = 'home';
export const MAINSCREEN_ROUTE_NAME = 'main-screen-home';
export const CAR_MAINSCREEN_ROUTE_NAME = 'car-main-screen-home';
export const LEGAL_MAINSCREEN_ROUTE_NAME = 'legal-main-screen-home';
export const USER_HOME_ROUTE_NAME = 'home';
var RoutesbyPerms = JSON.parse(localStorage.getItem('backend_routes'));
var routeAlliesbyperms = {};
let mainScreen = {cliamsdetails: 'main-screen-home'};
let medicalMainScreen = {cliamsdetails: 'medical-main-screen-home'};
let carMainScreen = {cliamsdetails: 'car-main-screen-home'};
let policyMainScreen = {cliamsdetails: 'policy-main-screen-home'};
let carAdminMainScreen = {cliamsdetails: 'car-admin-main-screen'};
let policyAdminMainScreen = {cliamsdetails: 'policy-admin-main-screen'};
let carReassignScreen = {cliamsdetails: 'car-reassign-screen'};
let policyReassignScreen = {cliamsdetails: 'policy-reassign-screen'};
let carNoassignScreen = {cliamsdetails: 'car-noassign-screen'};
let policyNoassignScreen = {cliamsdetails: 'policy-noassign-screen'};
let carArrangeScreen = {cliamsdetails: 'car-arrange-screen'};
let carFinanceScreen = {cliamsdetails: 'car-finance-screen'};
let policyArrangeScreen = {cliamsdetails: 'policy-arrange-screen'};
let carEntryScreen = {cliamsdetails: 'car-entry-screen-home'};
let legalMainScreen = {cliamsdetails: 'legal-main-screen-home'};
let insuranceCompany = {index: "insurance-company-index"};
let consumer = {index: 'consumer-index'};
let accidentType = {index: 'accident-type-index'};
let city = {index: 'city-index'};
let carCity = {index: 'car-city-index'};
let claimType = {index: 'claim-type-index'};
let vehicleType = {index: 'vehicle-type-index'};
let division = {index: 'division-index'};
let hospital = {index: 'hospital-index'};
let claimStatus = {index: 'claim-status-index',subs: 'claim-status-subs'};
let claimStatusField = {index: 'claim-status-field-index'};
let accident = {index: 'accident-index'};
let claim = {index: 'claim-index'};
let medicalClaim = {index: 'medical-claim-index'};
let partyPersonType = {index: 'party-person-type-index'};
let plateDigit = {index: 'plate-digit-index'};
let client = {index: 'client-index'};
let year = {index: 'year-index'};
let vehicleReportone = {index: 'vehicle-reportone'};
let vehicleReporttwo = {index: 'vehicle-reporttwo'};
let vehicleReportthree = {index: 'vehicle-reportthree'};
let vehicleReportfour = {index: 'vehicle-reportfour'};
let vehicleReportfive = {index: 'vehicle-reportfive'};
let policyReportone = {index: 'policy-reportone'};
let policyReporttwo = {index: 'policy-reporttwo'};
let policyReportthree = {index: 'policy-reportthree'};
let policyReportfour = {index: 'policy-reportfour'};
let medicalReportone = {index: 'medical-reportone'};
let medicalReporttwo = {index: 'medical-reporttwo'};
let medicalReportthree = {index: 'medical-reportthree'};
let medicalReportfour = {index: 'medical-reportfour'};
let medicalReportfive = {index: 'medical-reportfive'};
let medicalReportsix = {index: 'medical-reportsix'};
let medicalReportseven = {index: 'medical-reportseven'};
let partydata = {index: 'party-index'};
let partyPerson = {index: 'party-person-index'};
let hospitalContact = {index: 'hospital-contact-index'};
let consumerWork = {index: 'consumer-work-index'};
let vehicleModel = {index: 'vehicle-model-index'};
let partyType = {index: 'party-type-index'};
let menu = {index: 'menu-index'};
let role = {index: 'role-index'};
let permission = {index: 'permission-index'};
let documentType = {index: 'document-type-index'};
let user = {index: 'user-index', profile: 'profile',home: USER_HOME_ROUTE_NAME,};
let usernolist = {home: USER_HOME_ROUTE_NAME,profile: 'profile'};
let legalCaseIndex = {index: 'legal-case-index'};
let legalLitigationDegree = {index: 'legal-litigation-degree-index'};
let legalClientType = {index: 'legal-client-type-index'};
let legalClient = {index: 'legal-client-index'};
let legalCaseType = {index: 'legal-case-type-index'};
let legalCaseStatus = {index: 'legal-case-status-index'};
let legalPartyType = {index: 'legal-party-type-index'};
let legalParty = {index: 'legal-party-index'};
let carClient = {index: 'car-client-index'};
let carClaimType = {index: 'car-claim-type-index'};
let carClaim = {index: 'car-claim-index'};
let carDivision = {index: 'car-division-index'};
let carInsuranceCompany = {index: 'car-insurance-company-index'};
let carVehicleType = {index: 'car-vehicle-type-index'};
let carVehicleKind = {index: 'car-vehicle-kind-index'};
let caraccidentdestination = {index: 'car-accident-destination-index'};
let caraccidentdestinationType = {index: 'car-accident-destination-type-index'};
let carentryAction = {index: 'car-entry-action-index'};
let carentryActionType = {index: 'car-entry-action-type-index'};
let carVehicleModel = {index: 'car-vehicle-model-index'};
let carRefundReason = {index: 'car-refund-reason-index'};
let carClaimStatus = {index: 'car-claim-status-index',subs: 'claim-status-subs'};
let carClaimStatusField = {index: 'car-claim-status-field-index'};
let carSmsTemplate = {index: 'car-sms-template-index'};
let carCloseCollectedClaim = {index: 'car-close-collected-claim-index'};
let carCloseCollectedRow = {index: 'car-close-collected-row-index'};
let closeCollectedClaim = {index: 'close-collected-claim-index'};
let closeCollectedRow = {index: 'close-collected-row-index'};
let policyClaim = {index: 'policy-claim-index'};
let policyClient = {index: 'policy-client-index'};
let businessType = {index: 'business-type-index'};
let policyAssignedClaim = {index: 'policy-assigned-claim-index'};
let policyClaimNote = {index: 'policy-claim-note-index'};
let partyStatusStep = {index: 'party-status-step-index'};
let policyClaimStatus = {index: 'policy-claim-status-index'};
let policyClaimStatusFieldRow = {index: 'policy-claim-status-field-row-index'};
let policyClaimStatusField = {index: 'policy-claim-status-field-index'};
let policyClaimStatusRow = {index: 'policy-claim-status-row-index'};
let policyCity = {index: 'policy-city-index'};
let contractType = {index: 'contract-type-index'};
let medicalDischargeCondition = {index: 'medical-discharge-condition-index'};
let medicalAccidentDestination = {index: 'medical-accident-destination-index'};
let medicalCondition = {index: 'medical-condition-index'};
let bill = {index: 'bill-index'};
let legalContractType = {index: 'legal-contract-type-index'};
let legalCaseCategory = {index: 'legal-case-category-index'};
let legalAction = {index: 'legal-action-index'};
let legalActionRow = {index: 'legal-action-row-index'};
let legalActionField = {index: 'legal-action-field-index'};
let legalActionFieldRow = {index: 'legal-action-field-row-index'};
let legalActionRequest = {index: 'legal-action-request-index'};
let legalActionRequestUpdate = {index: 'legal-action-request-update-index'};
let legalAssignedLegalCase = {index: 'legal-assigned-legal-case-index'};
let legalCaseSession = {index: 'legal-case-session-index'};
let legalRequestType = {index: 'legal-request-type-index'};
let legalRequest = {index: 'legal-request-index'};
let systemSetting = {index: 'system-setting-index'};
// console.log(RoutesbyPerms);
// console.log(RoutesbyPerms['system-setting-index']);
  if(RoutesbyPerms) {
    if (RoutesbyPerms['main-screen-home']) {routeAlliesbyperms.mainScreen = mainScreen;}
    if (RoutesbyPerms['medical-main-screen-home']) {routeAlliesbyperms.medicalMainScreen = medicalMainScreen;}
    if (RoutesbyPerms['car-main-screen-home']) {routeAlliesbyperms.carMainScreen = carMainScreen;}
    if (RoutesbyPerms['policy-main-screen-home']) {routeAlliesbyperms.policyMainScreen = policyMainScreen;}
    if (RoutesbyPerms['car-admin-main-screen']) {routeAlliesbyperms.carAdminMainScreen = carAdminMainScreen;}
    if (RoutesbyPerms['policy-admin-main-screen']) {routeAlliesbyperms.policyAdminMainScreen = policyAdminMainScreen;}
    if (RoutesbyPerms['car-reassign-screen']) {routeAlliesbyperms.carReassignScreen = carReassignScreen;}
    if (RoutesbyPerms['policy-reassign-screen']) {routeAlliesbyperms.policyReassignScreen = policyReassignScreen;}
    if (RoutesbyPerms['car-arrange-screen']) {routeAlliesbyperms.carArrangeScreen = carArrangeScreen;}
    if (RoutesbyPerms['car-finance-screen']) {routeAlliesbyperms.carFinanceScreen = carFinanceScreen;}
    if (RoutesbyPerms['policy-arrange-screen']) {routeAlliesbyperms.policyArrangeScreen = policyArrangeScreen;}
    if (RoutesbyPerms['car-noassign-screen']) {routeAlliesbyperms.carNoassignScreen = carNoassignScreen;}
    if (RoutesbyPerms['policy-noassign-screen']) {routeAlliesbyperms.policyNoassignScreen = policyNoassignScreen;}
    if (RoutesbyPerms['car-entry-screen-home']) {routeAlliesbyperms.carEntryScreen = carEntryScreen;}
    if (RoutesbyPerms['legal-main-screen-home']) {routeAlliesbyperms.legalMainScreen = legalMainScreen;}
    if (RoutesbyPerms['insurance-company-index']) {routeAlliesbyperms.insuranceCompany = insuranceCompany;}
    if (RoutesbyPerms['consumer-index']) {routeAlliesbyperms.consumer = consumer;}
    if (RoutesbyPerms['accident-type-index']) {routeAlliesbyperms.accidentType = accidentType;}
    if (RoutesbyPerms['city-index']) {routeAlliesbyperms.city = city;}
    if (RoutesbyPerms['claim-type-index']) {routeAlliesbyperms.claimType = claimType;}
    if (RoutesbyPerms['vehicle-type-index']) {routeAlliesbyperms.vehicleType = vehicleType;}
    if (RoutesbyPerms['division-index']) {routeAlliesbyperms.division = division;}
    if (RoutesbyPerms['hospital-index']) {routeAlliesbyperms.hospital = hospital;}
    if (RoutesbyPerms['claim-status-index']) {routeAlliesbyperms.claimStatus = claimStatus;}
    if (RoutesbyPerms['claim-status-field-index']) {routeAlliesbyperms.claimStatusField = claimStatusField;}
    if (RoutesbyPerms['accident-index']) {routeAlliesbyperms.accident = accident;}
    if (RoutesbyPerms['claim-index']) {routeAlliesbyperms.claim = claim;}
    if (RoutesbyPerms['medical-claim-index']) {routeAlliesbyperms.medicalCaim = medicalClaim;}
    if (RoutesbyPerms['party-person-type-index']) {routeAlliesbyperms.partyPersonType = partyPersonType;}
    if (RoutesbyPerms['plate-digit-index']) {routeAlliesbyperms.plateDigit = plateDigit;}
    if (RoutesbyPerms['client-index']) {routeAlliesbyperms.client = client;}
    if (RoutesbyPerms['year-index']) {routeAlliesbyperms.year = year;}
    if (RoutesbyPerms['party-index']) {routeAlliesbyperms.partydata = partydata;}
    if (RoutesbyPerms['party-person-index']) {routeAlliesbyperms.partyPerson = partyPerson;}
    if (RoutesbyPerms['hospital-contact-index']) {routeAlliesbyperms.hospitalContact = hospitalContact;}
    if (RoutesbyPerms['consumer-work-index']) {routeAlliesbyperms.consumerWork = consumerWork;}
    if (RoutesbyPerms['vehicle-model-index']) {routeAlliesbyperms.vehicleModel = vehicleModel;}
    if (RoutesbyPerms['party-type-index']) {routeAlliesbyperms.partyType = partyType;}
    if (RoutesbyPerms['menu-index']) {routeAlliesbyperms.menu = menu;}
    if (RoutesbyPerms['role-index']) {routeAlliesbyperms.role = role;}
    if (RoutesbyPerms['permission-index']) {routeAlliesbyperms.permission = permission;}
    if (RoutesbyPerms['document-type-index']) {routeAlliesbyperms.documentType = documentType;}
    if (RoutesbyPerms['vehicle-reportone']) {routeAlliesbyperms.vehicleReportone = vehicleReportone;}
    if (RoutesbyPerms['vehicle-reporttwo']) {routeAlliesbyperms.vehicleReporttwo = vehicleReporttwo;}
    if (RoutesbyPerms['vehicle-reportthree']) {routeAlliesbyperms.vehicleReportthree = vehicleReportthree;}
    if (RoutesbyPerms['vehicle-reportfour']) {routeAlliesbyperms.vehicleReportfour = vehicleReportfour;}
    if (RoutesbyPerms['vehicle-reportfive']) {routeAlliesbyperms.vehicleReportfive = vehicleReportfive;}
    if (RoutesbyPerms['policy-reportone']) {routeAlliesbyperms.policyReportone = policyReportone;}
    if (RoutesbyPerms['policy-reporttwo']) {routeAlliesbyperms.policyReporttwo = policyReporttwo;}
    if (RoutesbyPerms['policy-reportthree']) {routeAlliesbyperms.policyReportthree = policyReportthree;}
    if (RoutesbyPerms['policy-reportfour']) {routeAlliesbyperms.policyReportfour = policyReportfour;}
    if (RoutesbyPerms['medical-reportone']) {routeAlliesbyperms.medicalReportone = medicalReportone;}
    if (RoutesbyPerms['medical-reporttwo']) {routeAlliesbyperms.medicalReporttwo = medicalReporttwo;}
    if (RoutesbyPerms['medical-reportthree']) {routeAlliesbyperms.medicalReportthree = medicalReportthree;}
    if (RoutesbyPerms['medical-reportfour']) {routeAlliesbyperms.medicalReportfour = medicalReportfour;}
    if (RoutesbyPerms['medical-reportfive']) {routeAlliesbyperms.medicalReportfive = medicalReportfive;}
    if (RoutesbyPerms['medical-reportsix']) {routeAlliesbyperms.medicalReportsix = medicalReportsix;}
    if (RoutesbyPerms['medical-reportseven']) {routeAlliesbyperms.medicalReportseven = medicalReportseven;}
    if (RoutesbyPerms['user-index']) {routeAlliesbyperms.user = user;}else{routeAlliesbyperms.user = usernolist}
    if (RoutesbyPerms['car-client-index']) {routeAlliesbyperms.carClient = carClient;}
    if (RoutesbyPerms['car-claim-type-index']) {routeAlliesbyperms.carClaimType = carClaimType;}
    if (RoutesbyPerms['car-claim-index']) {routeAlliesbyperms.carClaim = carClaim;}
    if (RoutesbyPerms['car-division-index']) {routeAlliesbyperms.carDivision = carDivision;}
    if (RoutesbyPerms['car-insurance-company-index']) {routeAlliesbyperms.carInsuranceCompany = carInsuranceCompany;}
    if (RoutesbyPerms['car-vehicle-type-index']) {routeAlliesbyperms.carVehicleType = carVehicleType;}
    if (RoutesbyPerms['car-vehicle-kind-index']) {routeAlliesbyperms.carVehicleKind = carVehicleKind;}
    if (RoutesbyPerms['car-vehicle-model-index']) {routeAlliesbyperms.carVehicleModel = carVehicleModel;}
    if (RoutesbyPerms['car-accident-destination-index']) {routeAlliesbyperms.caraccidentdestination = caraccidentdestination;}
    if (RoutesbyPerms['car-accident-destination-type-index']) {routeAlliesbyperms.caraccidentdestinationType = caraccidentdestinationType;}
    if (RoutesbyPerms['car-entry-action-index']) {routeAlliesbyperms.carentryAction = carentryAction;}
    if (RoutesbyPerms['car-entry-action-type-index']) {routeAlliesbyperms.carentryActionType = carentryActionType;}
    if (RoutesbyPerms['car-refund-reason-index']) {routeAlliesbyperms.carRefundReason = carRefundReason;}
    if (RoutesbyPerms['car-claim-status-index']) {routeAlliesbyperms.carClaimStatus = carClaimStatus;}
    if (RoutesbyPerms['car-claim-status-field-index']) {routeAlliesbyperms.carClaimStatusField = carClaimStatusField;}
    if (RoutesbyPerms['legal-case-index']) {routeAlliesbyperms.legalCase = legalCaseIndex;}
    if (RoutesbyPerms['legal-litigation-degree-index']) {routeAlliesbyperms.legalLitigationDegree = legalLitigationDegree;}
    if (RoutesbyPerms['legal-client-type-index']) {routeAlliesbyperms.legalClientType = legalClientType;}
    if (RoutesbyPerms['legal-client-index']) {routeAlliesbyperms.legalClient = legalClient;}
    if (RoutesbyPerms['legal-case-type-index']) {routeAlliesbyperms.legalCaseType = legalCaseType;}
    if (RoutesbyPerms['legal-case-status-index']) {routeAlliesbyperms.legalCaseStatus = legalCaseStatus;}
    if (RoutesbyPerms['legal-party-type-index']) {routeAlliesbyperms.legalPartyType = legalPartyType;}
    if (RoutesbyPerms['legal-party-index']) {routeAlliesbyperms.legalParty = legalParty;}
    if (RoutesbyPerms['car-city-index']) {routeAlliesbyperms.carCity = carCity;}
    if (RoutesbyPerms['car-sms-template-index']) {routeAlliesbyperms.carSmsTemplate = carSmsTemplate;}
    if (RoutesbyPerms['car-close-collected-claim-index']) {routeAlliesbyperms.carCloseCollectedClaim = carCloseCollectedClaim;}
    if (RoutesbyPerms['car-close-collected-row-index']) {routeAlliesbyperms.carCloseCollectedRow = carCloseCollectedRow;}
    if (RoutesbyPerms['close-collected-claim-index']) {routeAlliesbyperms.closeCollectedClaim = closeCollectedClaim;}
    if (RoutesbyPerms['close-collected-row-index']) {routeAlliesbyperms.closeCollectedRow = closeCollectedRow;}
    if (RoutesbyPerms['policy-claim-index']) {routeAlliesbyperms.policyClaim = policyClaim;}
    if (RoutesbyPerms['policy-client-index']) {routeAlliesbyperms.policyClient = policyClient;}
    if (RoutesbyPerms['policy-assigned-claim-index']) {routeAlliesbyperms.policyAssignedClaim = policyAssignedClaim;}
    if (RoutesbyPerms['policy-claim-note-index']) {routeAlliesbyperms.policyClaimNote = policyClaimNote;}
    if (RoutesbyPerms['party-status-step-index']) {routeAlliesbyperms.partyStatusStep = partyStatusStep;}
    if (RoutesbyPerms['policy-claim-status-index']) {routeAlliesbyperms.policyClaimStatus = policyClaimStatus;}
    if (RoutesbyPerms['policy-claim-status-field-row-index']) {routeAlliesbyperms.policyClaimStatusFieldRow = policyClaimStatusFieldRow;}
    if (RoutesbyPerms['policy-claim-status-field-index']) {routeAlliesbyperms.policyClaimStatusField = policyClaimStatusField;}
    if (RoutesbyPerms['policy-claim-status-row-index']) {routeAlliesbyperms.policyClaimStatusRow = policyClaimStatusRow;}
    if (RoutesbyPerms['business-type-index']) {routeAlliesbyperms.businessType = businessType;}
    if (RoutesbyPerms['policy-city-index']) {routeAlliesbyperms.policyCity = policyCity;}
    if (RoutesbyPerms['contract-type-index']) {routeAlliesbyperms.contractType = contractType;}
    if (RoutesbyPerms['medical-discharge-condition-index']) {routeAlliesbyperms.medicalDischargeCondition = medicalDischargeCondition;}
    if (RoutesbyPerms['medical-accident-destination-index']) {routeAlliesbyperms.medicalAccidentDestination = medicalAccidentDestination;}
    if (RoutesbyPerms['medical-condition-index']) {routeAlliesbyperms.medicalCondition = medicalCondition;}
    if (RoutesbyPerms['bill-index']) {routeAlliesbyperms.bill = bill;}
    if (RoutesbyPerms['legal-contract-type-index']) {routeAlliesbyperms.legalContractType = legalContractType;}
    if (RoutesbyPerms['legal-case-category-index']) {routeAlliesbyperms.legalCaseCategory = legalCaseCategory;}
    if (RoutesbyPerms['legal-action-index']) {routeAlliesbyperms.legalAction = legalAction;}
    if (RoutesbyPerms['legal-action-row-index']) {routeAlliesbyperms.legalActionRow = legalActionRow;}
    if (RoutesbyPerms['legal-action-field-row-index']) {routeAlliesbyperms.legalActionFieldRow = legalActionFieldRow;}
    if (RoutesbyPerms['legal-action-request-index']) {routeAlliesbyperms.legalActionRequest = legalActionRequest;}
    if (RoutesbyPerms['legal-action-request-update-index']) {routeAlliesbyperms.legalActionRequestUpdate = legalActionRequestUpdate;}
    if (RoutesbyPerms['legal-assigned-legal-case-index']) {routeAlliesbyperms.legalAssignedLegalCase = legalAssignedLegalCase;}
    if (RoutesbyPerms['legal-case-session-index']) {routeAlliesbyperms.legalCaseSession = legalCaseSession;}
    if (RoutesbyPerms['legal-request-type-index']) {routeAlliesbyperms.legalRequestType = legalRequestType;}
    if (RoutesbyPerms['legal-request-index']) {routeAlliesbyperms.legalRequest = legalRequest;}
    if (RoutesbyPerms['legal-action-field-index']) {routeAlliesbyperms.legalActionField = legalActionField;}
    if (RoutesbyPerms['system-setting-index']) {routeAlliesbyperms.systemSetting = systemSetting;}
  }
  else{
      routeAlliesbyperms.user = usernolist
    }
// console.log(routeAlliesbyperms);
const routeAllies = {
  landing: {
    home: HOME_ROUTE_NAME,
  },
  auth: {
    login: LOGIN_ROUTE_NAME,
    logout: {
      path: `/${LOGOUT_ROUTE_NAME}`,
      name: LOGOUT_ROUTE_NAME
    }
  },
  ...routeAlliesbyperms
};
export default routeAllies;
