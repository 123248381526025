import axios from 'axios';
const prefix = 'Resources';
const Resource = a => `${prefix}/${a}`;
export default {
  insurancecompanies(params = {}, config = {}) {
    const url = Resource('InsuranceCompanies');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  carClaimStatusRows(params = {}, config = {}) {
    const url = Resource('carClaimStatusRows');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  accidentTypes(params = {}, config = {}) {
    const url = Resource('AccidentTypes');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  accidents(params = {}, config = {}) {
    const url = Resource('Accidents');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  consumers(params = {}, config = {}) {
    const url = Resource('Consumers');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  cities(params = {}, config = {}) {
    const url = Resource('Cities');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  claimTypes(params = {}, config = {}) {
    const url = Resource('ClaimTypes');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  vehicleTypes(params = {}, config = {}) {
    const url = Resource('VehicleTypes');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  divisions(params = {}, config = {}) {
    const url = Resource('Divisions');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  hospitals(params = {}, config = {}) {
    const url = Resource('Hospitals');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  claimStatuses(params = {}, config = {}) {
    const url = Resource('ClaimStatuses');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  claimStatusFields(params = {}, config = {}) {
    const url = Resource('ClaimStatusFields');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  claims(params = {}, config = {}) {
    const url = Resource('Claims');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  partyPersonTypes(params = {}, config = {}) {
    const url = Resource('PartyPersonTypes');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  plateDigits(params = {}, config = {}) {
    const url = Resource('PlateDigits');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  clientresources(params = {}, config = {}) {
    const url = Resource('Clients');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  claimyears(params = {}, config = {}) {
    const url = Resource('Years');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  partiesdata(params = {}, config = {}) {
    const url = Resource('Parties');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  partyPersons(params = {}, config = {}) {
    const url = Resource('PartyPersons');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  hospitalContacts(params = {}, config = {}) {
    const url = Resource('HospitalContacts');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  consumerWorks(params = {}, config = {}) {
    const url = Resource('ConsumerWorks');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  partyStatuses(params = {}, config = {}) {
    const url = Resource('PartyStatuses');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  partyStatusRows(params = {}, config = {}) {
    const url = Resource('PartyStatusRows');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  vehicleModels(params = {}, config = {}) {
    const url = Resource('VehicleModels');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  partyTypes(params = {}, config = {}) {
    const url = Resource('PartyTypes');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  menus(params = {}, config = {}) {
    const url = Resource('Menus');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  permissions(params = {}, config = {}) {
    const url = Resource('Permissions');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  roles(params = {}, config = {}) {
    const url = Resource('Roles');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  documentTypes(params = {}, config = {}) {
    const url = Resource('DocumentTypes');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  legalCases(params = {}, config = {}) {
    const url = Resource('LegalCases');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  legalCaseStatuses(params = {}, config = {}) {
    const url = Resource('LegalCaseStatuses');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  legalCaseTypes(params = {}, config = {}) {
    const url = Resource('LegalCaseTypes');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  legalClients(params = {}, config = {}) {
    const url = Resource('LegalClients');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  legalClientTypes(params = {}, config = {}) {
    const url = Resource('LegalClientTypes');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  legalLitigationDegrees(params = {}, config = {}) {
    const url = Resource('LegalLitigationDegrees');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  legalParties(params = {}, config = {}) {
    const url = Resource('LegalParties');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  legalPartyTypes(params = {}, config = {}) {
    const url = Resource('LegalPartyTypes');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  carInsuranceCompanies(params = {}, config = {}) {
    const url = Resource('CarInsuranceCompanies');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  carDivisions(params = {}, config = {}) {
    const url = Resource('CarDivisions');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  carClients(params = {}, config = {}) {
    const url = Resource('CarClients');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  carClaims(params = {}, config = {}) {
    const url = Resource('CarClaims');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  carAccidentDestinations(params = {}, config = {}) {
    const url = Resource('CarAccidentDestinations');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  carAccidentDestinationTypes(params = {}, config = {}) {
    const url = Resource('CarAccidentDestinationTypes');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  carEntryActions(params = {}, config = {}) {
    const url = Resource('CarEntryActions');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  carEntryActionTypes(params = {}, config = {}) {
    const url = Resource('CarEntryActionTypes');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  carClaimTypes(params = {}, config = {}) {
    const url = Resource('CarClaimTypes');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  carVehicleKinds(params = {}, config = {}) {
    const url = Resource('CarVehicleKinds');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  carVehicleTypes(params = {}, config = {}) {
    const url = Resource('CarVehicleTypes');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  carVehicleModels(params = {}, config = {}) {
    const url = Resource('CarVehicleModels');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  carRefundReasons(params = {}, config = {}) {
    const url = Resource('CarRefundReasons');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  carClaimStatuses(params = {}, config = {}) {
    const url = Resource('CarClaimStatuses');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  carClaimStatusFields(params = {}, config = {}) {
    const url = Resource('CarClaimStatusFields');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  carClaimStatusFieldRows(params = {}, config = {}) {
    const url = Resource('CarClaimStatusFieldRows');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  carCities(params = {}, config = {}) {
    const url = Resource('CarCities');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  carSmsTemplates(params = {}, config = {}) {
    const url = Resource('CarSmsTemplates');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  carCloseCollectedClaims(params = {}, config = {}) {
    const url = Resource('CarCloseCollectedClaims');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  carCloseCollectedRows(params = {}, config = {}) {
    const url = Resource('CarCloseCollectedRows');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  closeCollectedClaims(params = {}, config = {}) {
    const url = Resource('CloseCollectedClaims');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  closeCollectedRows(params = {}, config = {}) {
    const url = Resource('CloseCollectedRows');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  medicalClaims(params = {}, config = {}) {
    const url = Resource('MedicalClaims');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  businessTypes(params = {}, config = {}) {
    const url = Resource('BusinessTypes');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  policyClaims(params = {}, config = {}) {
    const url = Resource('PolicyClaims');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  policyClients(params = {}, config = {}) {
    const url = Resource('PolicyClients');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  policyClaimStatusRows(params = {}, config = {}) {
    const url = Resource('PolicyClaimStatusRows');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  policyClaimStatusFields(params = {}, config = {}) {
    const url = Resource('PolicyClaimStatusFields');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  policyClaimStatusFieldRows(params = {}, config = {}) {
    const url = Resource('PolicyClaimStatusFieldRows');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  policyClaimStatuses(params = {}, config = {}) {
    const url = Resource('PolicyClaimStatuses');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  policyClaimNotes(params = {}, config = {}) {
    const url = Resource('PolicyClaimNotes');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  policyAssignedClaims(params = {}, config = {}) {
    const url = Resource('PolicyAssignedClaims');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  policyCities(params = {}, config = {}) {
    const url = Resource('PolicyCities');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  contractTypes(params = {}, config = {}) {
    const url = Resource('ContractTypes');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  medicalDischargeConditions(params = {}, config = {}) {
    const url = Resource('MedicalDischargeConditions');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  medicalAccidentDestinations(params = {}, config = {}) {
    const url = Resource('MedicalAccidentDestinations');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  partyStatusSteps(params = {}, config = {}) {
    const url = Resource('PartyStatusSteps');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  medicalConditions(params = {}, config = {}) {
    const url = Resource('MedicalConditions');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  bills(params = {}, config = {}) {
    const url = Resource('Bills');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  legalContractTypes(params = {}, config = {}) {
    const url = Resource('LegalContractTypes');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  legalCaseCategories(params = {}, config = {}) {
    const url = Resource('LegalCaseCategories');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  legalActions(params = {}, config = {}) {
    const url = Resource('LegalActions');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  legalActionRows(params = {}, config = {}) {
    const url = Resource('LegalActionRows');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  legalActionFields(params = {}, config = {}) {
    const url = Resource('LegalActionFields');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  legalActionFieldRows(params = {}, config = {}) {
    const url = Resource('LegalActionFieldRows');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  legalActionRequests(params = {}, config = {}) {
    const url = Resource('LegalActionRequests');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  legalActionRequestUpdates(params = {}, config = {}) {
    const url = Resource('LegalActionRequestUpdates');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  legalAssignedLegalCases(params = {}, config = {}) {
    const url = Resource('LegalAssignedLegalCases');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  legalCaseSessions(params = {}, config = {}) {
    const url = Resource('LegalCaseSessions');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  legalRequestTypes(params = {}, config = {}) {
    const url = Resource('LegalRequestTypes');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  discounts(params = {}, config = {}) {
    const url = Resource('LegalRequests');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
  systemSettings(params = {}, config = {}) {
    const url = Resource('SystemSettings');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  },
}
