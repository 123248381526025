/* SarhERP Project Copyright © 2021 SarhSoft All rights reserved M.Farhat */

export default {
      name_ar: 'name_ar',
    name_en: 'name_en',
    setting_type: 'setting_type',
    setting_category: 'setting_category',
    setting_value: 'setting_value',
    setting_status: 'setting_status',

};
