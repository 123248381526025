

export default {
  q: '?',
  sar: 'ريال',
  false: 'Disabled',
  true: 'Enabled',
  progress: 'progress ...',
  id: '#',
  ar: 'العربية',
  en: 'English',
  login: 'Login',
  home: 'Home',
  mainScreenHome: 'Claims Details',
  carMainScreenHome: 'Claims Details',
  policyMainScreenHome: 'Policies Details',
  legalMainScreenHome: 'Cases Details',
  done: 'Done',
  logout: 'Logout',
  cancel: 'Cancel',
  yes: 'Yes',
  no: 'No',
  enabled: 'Enabled',
  disabled: 'Disabled',
  save: 'Save',
  change: 'Change',
  options: 'options',
  create: 'create',
  store: 'Create',
  update: 'Update',
  upload: 'Upload',
  download: 'Download',
  control: 'control',
  none: 'None',
  na: 'Na',
  export: 'Export',
  close: 'Close',
  edit: 'Edit',
  add: 'Add',
  register: 'Register',
  print: 'Print',
  today: 'Today',
  day: 'Day',
  date: 'Date',
  now: 'Now',
  am: 'AM',
  pm: 'PM',
  error: 'Error !!',
  search: 'Search',
  the_search: 'Search',
  calculator: 'Calculator',
  mortgage: 'Mortgage',
  personalProgram: 'Personal Program',
  propertyProgram: 'Property Program',
  flexibleProgram: 'Flexible Program',
  year: 'Year',
  showDetails: 'Show Details',
  Details: 'Details ',
  information: 'Information ',
  the_information: 'The Information ',
  setting: 'Setting',
  month: 'Month',
  subs: 'Status Fields',
  by_accident: 'Search by Accident Number',
  by_id: 'Search by Identity Number',
  by_legal_id: 'Identity / C.R Number',
  by_case: 'Case Number',
  by_claim: 'Claim Number',
  by_policy: 'Policy Number',
  by_client: 'Client',
  by_serial_number: 'Serial Number',
  by_consumer_work: 'Injered Work',
  by_plate: 'Plate',
  non_defined: 'Un-Defined',
  greg_letter: ' AD',
  hijri_letter: ' AH',
  import: 'Import',
  persons_in_party_vehicle:'Persons in vehicle ',
  add_consumer:'Add injered',
  add_accident:'Add accident',
  add_claim:'Add claim',
  entry_actions:'Data Entry Actions',
  add_party_person:'Add person',
  add_party:'Add party',
  add_party_status:'Add claim status',
  add_claim_status:'Add claim status',
  add_car_claim_status:'Add Action',
  status_date_view:'Status Created at',
  is_consumer_view:'injered Belong to this Party',
  is_consumer_driver_view:'injered is the driver',
  plate_id:'Plate ID',
  attach_document:'Documents',
  car_cashier_screen: 'Collector Dashboard',
  car_claim_type1:'Full Insurance',
  car_claim_type2:'Third party insurance',
  claim_status_assign_type:'Action Assign Type',
  assign_type1:'Private Assign',
  assign_type2:'Public Assign',
  parties_numbers:{
    1:  'First',
    2:  'Second',
    3:  'Third',
    4:  'Fourth',
    5:  'Fifth',
    6:  'Sixth',
    7:  'Seventh',
    8:  'Eighth',
    9:  'Ninth',
    10: 'Tenth',
  },
};
